const selector = "#signage-loader-bg"
const loaderElement = document.querySelector(selector)

export const hide = () => {
    loaderElement.classList.add('d-none')
}

export const show = () => {
    loaderElement.classList.remove('d-none')
}
