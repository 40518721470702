


/**
 * moment.js
 */
window.moment = require('moment') //moment.js
require('moment-timezone'); //moment.jsタイムゾーンを読みこむ
moment.tz.setDefault("Asia/Tokyo"); //moemnt.jsのタイムゾーンを日本に設定

/**
 * bootstrap
 */

/**
 * axios
 */
window.axios = require('axios'); //axios
//クロスドメインのためにヘッダ追加
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// エラーの時でもレスポンスを返すように変更する（その方が扱いやすい）
window.axios.interceptors.response.use(
  response => response,
  error => {
    const err = error.response
    if (!err) {
      return error
    }

    // 認証エラーの場合はリロード（セッション切れ対策）
    if (err.status === 401) {
      return location.reload()
    }

    return err
  }
)

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  // ヘッダにCSRFトークン追加
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

