export default class {

    /**
     * momentクラスのインスタンス
     * 
     * @type {Moment}
     */
    moment;

    /**
     * @type {Object}
     */
    events = {
        /**
         * 1分ごとに実行されるコールバックの配列
         * 
         * @type {Array<Function>}
         */
        miniteChanged: [],
        /**
         * 時間の単位が変わったら実行されるコールバックの配列
         * 
         * @type {Array<Function>}
         */

        hourChanged: [],
        /**
         * 時間の単位が変わったら実行されるコールバックの配列
         * 
         * @type {Array<Function>}
         */
        dayChanged: [],
    }

    constructor() {
        const date = new Date(signageScript.now)
        this.moment = window.moment(date.toISOString())
        this.everyMinite()
    }

    /**
     * ゲッター
     * 現在時刻のmomentを返す
     *
     * @readonly
     */
    get now() {
        return this.moment
    }

    /**
     * イベント名一覧を取得してeventListにセット
     *
     */
    setEventList() {
        this.eventList = Object.keys(this.events)
    }

    /**
     * 1分ごとに実行
     * 
     * @returns {void}
     */
    everyMinite() {
        setInterval(() => {
            this.addOneMiniteAndDispatchEvent()
        }, 1 * 60 * 1000);
    }


    /**
     * 現在時刻を更新して、イベントを発火
     * 
     * @returns {void}
     */
    addOneMiniteAndDispatchEvent() {
        const prevMoment = this.moment.clone()
        this.now.add(1, "minutes")

        this.dispatchEvent('miniteChanged')

        if (prevMoment.hour() !== this.now.hour()) {
            this.dispatchEvent('hourChanged')
        }

        if (prevMoment.day() !== this.now.day()) {
            this.dispatchEvent('dayChanged')
        }
    }

    /**
     * イベント追加
     *
     * @param {('miniteChanged'|'hourChanged'|'dayChanged')} event 
     * @param {Function} callback
     * @returns {boolean}
     */
    addEventListener(event, callback) {
        if (!this.validateEventName(event) || typeof (callback) !== "function") {
            console.warn("引数が正しくありません。")
            return false;
        }

        this.events[event].push(callback)
        return true;
    }

    /**
     * イベント削除
     *
     * @param {('miniteChanged'|'hourChanged'|'dayChanged')} event 
     * @param {Function} callback
     * @returns {boolean}
     */
    removeEventListener(event, callback) {
        if (!this.validateEventName(event) || typeof (callback) !== "function") {
            console.warn("引数が正しくありません。")
            return false;
        }

        this.events[event] = this.events[event]
            .filter(_callback => _callback !== callback)
        return true;
    }

    /**
     * イベント発火
     *
     * @param {('miniteChanged'|'hourChanged'|'dayChanged')} event 
     * @returns {boolean}
     * 
     * @private
     */
    dispatchEvent(event) {
        if (!this.validateEventName(event)) {
            return false;
        }

        this.events[event].forEach(callback => callback());
    }


    /**
     * イベント名のバリデーション
     *
     * @param {string} eventName
     * @returns {boolean}
     * 
     * @private
     */
    validateEventName(eventName) {
        const result = this.events.hasOwnProperty(eventName)
        if (!result) {
            console.warn(`${eventName}は存在しないイベントです`)
        }
        return result;
    }
}
