function init() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/sw.js').then(registration => {
                console.log('SW registered: ', registration);
                // registration.pushManager.subscribe({ userVisibleOnly: true });
            }).catch(registrationError => {
            });
        });
    }
}

init()
