// nameSpace
window.signageUtil = {};

// 自作ローディング画面
import * as SignageLoader from './util/signage-loader'
window.signageUtil.loader = SignageLoader;

// 時間を管理
import Clock from './util/clock'
window.signageUtil.clock = new Clock
